import React from "react";

// import Layout from "../components/layout"
import SEO from "../global-components/seo";

const NotFoundPage = () => (
  // <Layout>
  <div>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>
  // </Layout>
);

export default NotFoundPage;
